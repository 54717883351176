import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "usuarioTemplate",
    "usuarioAdministrativoTemplate",
    "matriculaTemplate",
    "utilizadorExternoTemplate",

    "insertionPoint",

    "utilizadorSelect",
  ]

  static whatToShow = {
    'Funcionário': ['usuario'],
    'Setor Administrativo': ['usuarioAdministrativo'],
    'Aluno': ['matricula'],
    'Outros (Administrativo)': ['utilizadorExterno'],
    'Outros (Funcionário)': ['utilizadorExterno'],
  }

  connect() {
    this.change()
  }

  change() {
    const utilizador = this.utilizadorSelectTarget.value
    const whatToShow = this.constructor.whatToShow[utilizador] || []
    this.insertionPointTarget.innerHTML = whatToShow.map((what) => {
      return this[what + "TemplateTarget"].innerHTML
    }).join("")
  }
}
